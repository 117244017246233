<template>
  <v-card
    class="card-shadow border-radius-xl px-4 py-5 mt-7"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div
      class="bg-gradient-info shadow-success border-radius-lg py-3 pe-1 mb-6 mt-n12"
    >
      <div class="chart-area" v-if="!updatingClientInvoices">
        <canvas
          :height="$route.name == 'VrDefault' ? '370' : '170'"
          :id="salesViewsID"
        >
        </canvas>
      </div>
      <div v-else style="height: 370px">LOADING</div>
    </div>
    <v-row class="ma-0 pa-0 justify-space-between">
      <h6
        class="ms-2 mt-2 mb-0 text-h6 font-weight-bold"
        :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
      >
        Einnahmen {{ selectedYear ? selectedYear : currentYear }} -
        {{ completeAmount.toFixed(2) }}€
      </h6>
      <v-btn
        elevation="0"
        height="43"
        class="font-weight-bold text-uppercase btn-default btn-outline-default shadow-none py-2 px-6 me-2"
        color="transparent"
        small
        @click="
          csvExportYear(
            filteredInvoices,
            selectedYear ? selectedYear : currentYear,
            client
          )
        "
      >
        <v-icon>ni ni-archive-2 me-1</v-icon>
        Export CSV
      </v-btn>
    </v-row>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";
import administrationData from "@/mixins/administrationMixin";

export default {
  name: "card-client-revenue",
  mixins: [administrationData],
  data: function () {
    return {
      salesViewsID: "card-client-revenue",
      myChart: null,
    };
  },
  computed: {
    client() {
      if (this.clients) {
        try {
          return this.clients.find(
            (client) => client._id == this.$route.params.id
          );
        } catch (error) {
          return null;
        }
      } else {
        return "NOPE";
      }
    },
    clientInvoices() {
      return this.$store.getters.clientInvoices;
    },
    completeAmount() {
      var thisYear = new Date().getFullYear();
      var amount = 0;
      var count = 0;
      this.clientInvoices.forEach((element) => {
        if (
          element.transactions[0].related_resources[0].sale.state ==
            "completed" &&
          !element.cancelation_invoice
        ) {
          var date = new Date(element.create_time);
          if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
            amount += parseFloat(element.transactions[0].amount.total);
            count += 1;
          }
        }
      });
      return amount;
    },
    filteredInvoices() {
      return this.clientInvoices.filter(
        (element) =>
          element.transactions[0].related_resources[0].sale.state ==
            "completed" && !element.cancelation_invoice
      );
    },
    // erstattungen() {
    //   let array = [];
    //   this.clientInvoices.forEach((element) => {
    //     console.log(element);
    //     if (
    //       element.transactions[0].related_resources[0].sale.state == "completed"
    //     ) {
    //       // var date = new Date(element.create_time);
    //       // if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
    //       //   array[date.getMonth()] += parseFloat(
    //       //     element.transactions[0].amount.total
    //       //   );
    //       // }
    //     } else {
    //       array.push(element);
    //     }
    //   });
    //   return array;
    // },
    monthlyData() {
      var array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var thisYear = new Date().getFullYear();
      this.clientInvoices.forEach((element) => {
        // console.log(element);
        // if (element.state == "approved" && !element.canceled) {
        //   var date = new Date(element.create_time);
        //   if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
        //     array[date.getMonth()] += parseFloat(
        //       element.transactions[0].amount.total
        //     );
        //   }
        // }
        if (
          element.transactions[0].related_resources[0].sale.state ==
            "completed" &&
          !element.cancelation_invoice
        ) {
          var date = new Date(element.create_time);
          if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
            array[date.getMonth()] += parseFloat(
              element.transactions[0].amount.total
            );
          }
        }
      });

      return array;
    },
  },
  watch: {
    clientInvoices() {
      if (this.clientInvoices) this.generateChart();
    },
  },
  mounted() {
    // this.$store.dispatch("getClientInvoices", this.$route.params.id);
  },
  methods: {
    generateChart() {
      if (this.myChart) this.myChart.destroy();
      this.myChart = new Chart(
        document.getElementById(this.salesViewsID).getContext("2d"),
        {
          type: "bar",
          data: {
            labels: [
              "Jan",
              "Feb",
              "Mär",
              "Apr",
              "Mai",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Okt",
              "Nov",
              "Dez",
            ],
            datasets: [
              {
                label: "Einnahmen in €",
                tension: 0.4,
                borderWidth: 0,
                borderRadius: 4,
                borderSkipped: false,
                backgroundColor: "rgba(255, 255, 255, .8)",
                data: this.monthlyData,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                  color: "rgba(255, 255, 255, .2)",
                },
                ticks: {
                  display: true,
                  color: "#f8f9fa",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Quicksand",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#f8f9fa",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Quicksand",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        }
      );
    },
  },
  beforeDestroy() {
    if (this.myChart) this.myChart.destroy();
  },
};
</script>
