<template>
  <div>
    <v-card
      class="card-shadow border-radius-xl px-4 py-5 mt-7"
      :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
    >
      <div
        class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 mb-6 mt-n12"
      >
        <div class="chart-area">
          <canvas
            :height="$route.name == 'VrDefault' ? '370' : '170'"
            :id="salesViewsID"
          >
          </canvas>
        </div>
      </div>
      <h6
        class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
        :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
      >
        Ticketverkäufe {{ selectedYear ? selectedYear : currentYear }} -
        {{ completeAmount }} Stück
      </h6>
    </v-card>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import administrationData from "@/mixins/administrationMixin";

export default {
  name: "card-client-sales",
  mixins: [administrationData],
  data: function () {
    return {
      salesViewsID: "clientSalesView",
      myChart: null,
    };
  },
  props: {
    clientId: {
      type: String,
      default: null,
    },
  },
  computed: {
    clientInvoices() {
      return this.$store.getters.clientInvoices;
    },
    // currentYear() {
    //   var now = new Date();
    //   return now.getFullYear();
    // },
    // completeAmount() {
    //   var thisYear = new Date().getFullYear();
    //   var amount = 0;
    //   var count = 0;
    //   this.clientInvoices.forEach((element) => {
    //     if (element.state == "approved") {
    //       var date = new Date(element.create_time);
    //       if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
    //         amount += parseFloat(element.transactions[0].amount.total);
    //         count += 1;
    //       }
    //     }
    //   });
    //   return amount;
    // },
    // monthlyData() {
    //   var array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //   var thisYear = new Date().getFullYear();
    //   this.clientInvoices.forEach((element) => {
    //     if (element.state == "approved") {
    //       var date = new Date(element.create_time);
    //       if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
    //         array[date.getMonth()] += parseFloat(
    //           element.transactions[0].amount.total
    //         );
    //       }
    //     }
    //   });

    //   return array;
    // },
    completeAmount() {
      var amount = 0;
      var thisYear = new Date().getFullYear();
      this.clientInvoices.forEach((element) => {
        if (
          element.transactions[0].related_resources[0].sale.state ==
            "completed" &&
          !element.cancelation_invoice
        ) {
          var date = new Date(element.create_time);
          if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
            // amount += 1;
            if (element?.transactions[0]?.item_list?.items) {
              amount += element.transactions[0].item_list.items.length;
            } else {
              // console.log(element);
            }
          }
        }
      });
      return amount;
    },
    monthlyData() {
      var array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      var thisYear = new Date().getFullYear();
      this.clientInvoices.forEach((element) => {
        if (
          element.transactions[0].related_resources[0].sale.state ==
            "completed" &&
          !element.cancelation_invoice
        ) {
          var date = new Date(element.create_time);
          if ((this.selectedYear ?? thisYear) == date.getFullYear()) {
            // array[date.getMonth()] += 1;
            if (element.transactions[0].item_list.items) {
              array[date.getMonth()] +=
                element.transactions[0].item_list.items.length;
            } else {
              // console.log(element);
            }
          }
        }
      });

      return array;
    },
  },
  watch: {
    selectedYear() {
      var thisYear = new Date().getFullYear();
      this.$store.dispatch("getClientInvoices", {
        id: this.$route.params.id,
        year: this.selectedYear ?? thisYear,
      });
    },
    clientInvoices() {
      if (this.clientInvoices) this.generateChart();
    },
  },
  methods: {
    generateChart() {
      if (this.myChart) this.myChart.destroy();
      this.myChart = new Chart(
        document.getElementById(this.salesViewsID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: [
              "Jan",
              "Feb",
              "Mär",
              "Apr",
              "Mai",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Okt",
              "Nov",
              "Dez",
            ],
            datasets: [
              {
                label: "Verkäufe",
                tension: 0,
                pointRadius: 5,
                pointBackgroundColor: "rgba(255, 255, 255, .8)",
                pointBorderColor: "transparent",
                borderColor: "rgba(255, 255, 255, .8)",
                borderWidth: 4,
                backgroundColor: "transparent",
                fill: true,
                data: this.monthlyData,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                  color: "rgba(255, 255, 255, .2)",
                },
                ticks: {
                  display: true,
                  color: "#f8f9fa",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Quicksand",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#f8f9fa",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Quicksand",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        }
      );
    },
  },
  mounted() {
    var thisYear = new Date().getFullYear();
    this.$store.dispatch("getClientInvoices", {
      id: this.$route.params.id,
      year: this.selectedYear ?? thisYear,
    });
  },
  beforeDestroy() {
    if (this.myChart) this.myChart.destroy();
  },
};
</script>
