import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        clients: [],
        updatingClients: false,
        clientEvents: [],
        updatingClientEvents: false,
        clientInvoices: [],
        updatingClientInvoices: false,
        clientEventEntries: [],
        clientEventInvoices: [],
        clientEventComments: [],
        selectedYear: null,
        selectedClientEvent: null,
        clientEventNotifications: [],
    }
}

const state = getDefaultState()

const getters = {
    clients: state => {
        return state.clients
    },
    updatingClients: state => {
        return state.updatingClients
    },
    clientEvents: state => {
        return state.clientEvents
    },
    updatingClientEvents: state => {
        return state.updatingClientEvents
    },
    clientInvoices: state => {
        return state.clientInvoices
    },
    updatingClientInvoices: state => {
        return state.updatingClientInvoices
    },
    updatingClientInvoices: state => {
        return state.updatingClientInvoices
    },
    clientEventInvoices: state => {
        return state.clientEventInvoices
    },
    clientEventComments: state => {
        return state.updatingClientInvoices
    },
    selectedYear: state => {
        return state.selectedYear
    },
    selectedClientEvent: state => {
        return state.selectedClientEvent
    },
    clientEventNotifications: state => {
        return state.clientEventNotifications
    },
    clientEventEntries: state => {
        return state.clientEventEntries
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    clients: (state, payload) => {
        state.clients = payload
    },
    updatingClients: (state, payload) => {
        state.updatingClients = payload
    },
    clientEvents: (state, payload) => {
        state.clientEvents = payload
    },
    clientEventInvoices: (state, payload) => {
        state.clientEventInvoices = payload
    },
    updatingClientEvents: (state, payload) => {
        state.updatingClientEvents = payload
    },
    clientInvoices: (state, payload) => {
        state.clientInvoices = payload
    },
    updatingClientInvoices: (state, payload) => {
        state.updatingClientEvents = payload
    },
    selectedYear: (state, payload) => {
        state.selectedYear = payload
    },
    selectedClientEvent: (state, payload) => {
        state.selectedClientEvent = payload
    },
    clientEventNotifications: (state, payload) => {
        state.clientEventNotifications = payload
    },
    clientEventEntries: (state, payload) => {
        state.clientEventEntries = payload
    },
}

const actions = {

    getClients: ({ commit }) => {
        commit('updatingClients', true)
        axiosAuth.get('/administration/clients', {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClients', false)
                    if (!res.data.message) {
                        commit('clients', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientEvents: ({ commit }, payload) => {
        commit('clientEvents', [])
        commit('updatingClientEvents', true)
        axiosAuth.get(`/administration/clients/events/${payload.id}?year=${payload.year}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClientEvents', false)
                    if (!res.data.message) {
                        commit('clientEvents', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientEventNotifications: ({ commit }, payload) => {
        commit('clientEventNotifications', [])
        commit('updatingClientEvents', true)
        axiosAuth.get(`/administration/clients/eventnotifications/${payload.id}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClientEvents', false)
                    if (!res.data.message) {
                        commit('clientEventNotifications', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientEventEntries: ({ commit }, payload) => {
        commit('clientEventEntries', [])
        commit('updatingClientEvents', true)
        axiosAuth.get(`/administration/clients/evententries/${payload.id}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClientEvents', false)
                    if (!res.data.message) {
                        commit('clientEventEntries', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientEventInvoices: ({ commit }, payload) => {
        commit('clientEventInvoices', [])
        commit('updatingClientEvents', true)
        axiosAuth.get(`/administration/clients/eventinvoices/${payload.id}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClientEvents', false)
                    if (!res.data.message) {
                        commit('clientEventInvoices', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientEventById: ({ commit }, payload) => {
        if (!payload.reset == false)
            commit('selectedClientEvent', null)
        commit('updatingClientEvents', true)
        axiosAuth.get(`/administration/clients/eventById/${payload.id}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    commit('updatingClientEvents', false)
                    if (!res.data.message) {
                        commit('selectedClientEvent', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClients', false)
                }
            )
    },

    getClientInvoices: ({ commit }, payload) => {
        console.log('get client invoices')
        commit('clientInvoices', [])
        commit('updatingClientInvoices', true)
        axiosAuth.get(`/administration/clients/events/invoices/${payload.id}?year=${payload.year}`, {
            headers: {
                version: 'v1.1'
            }
        })
            .then(
                res => {
                    console.log(res)
                    commit('updatingClientInvoices', false)
                    if (!res.data.message) {
                        commit('clientInvoices', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updatingClientInvoices', false)
                }
            )
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}