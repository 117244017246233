<template>
  <v-row
    class="justify-center d-flex mt-n16 mx-0 bg-white position-relative border-radius-xl shadow px-4"
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <div
          style="margin-top: 100px; width: 800px; max-width: 100%"
          class="mx-5 text-dark mx-3"
        >
          <h1>Impressum</h1>
          <br />
          Oelsnitzer Kultur GmbH
          <br />
          Schloßstr. 32<br />08606 Oelsnitz/Vogtl.<br /><br />
          E-Mail: kultur@oelsnitz.de<br />

          Telefon: (03 74 21) 7 09 73<br /><br />
          Registergericht Chemnitz HRB: 15 272;<br />
          USt-IdNr: DE 251967391<br /><br />

          Geschäftsführung: Daniel Petri (verantwortliche Person)<br />
          Vorsitzender des Aufsichtsrates: Mario Horn<br /><br /><br />

          Ansprechpartner (redaktionell verantwortliche Person)<br /><br />
          Jan Windisch<br />
          E-Mail: jan.windisch(at)oelsnitz.de<br />
          Telefon: (03 74 21) 7 09 73<br /><br />
          Adresse: siehe oben<br /><br />
          <h4 class="my-2">Streitschlichtung</h4>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
            >https://ec.europa.eu/consumers/odr</a
          >. Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht
          bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.

          <h4 class="my-2">Urheberrecht</h4>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Impressum",
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
};
</script>